<template>
  <div
    v-shortkey="['shift', 'esc']"
    class="footer--outer"
    @shortkey="loginOrToggleUI"
  >
    <WithLocationSummaries v-slot="{ locations }">
      <BasePageSection
        :class="['footer', 'py-0']"
        bg-color="#0B1B8F"
        :pa-0="['xs', 'sm'].includes($mq)"
        full-bleed-bg
      >
        <VLayout>
          <VContainer class="locations pa-0" fluid grid-list-md>
            <VLayout
              v-for="(loc, index) in locations"
              :key="index"
              justify-center
              align-space-around
              row
              wrap
              fill-height
            >
              <VFlex sm4 xs12>
                <BaseThemeComponent name="FooterLocation" :location="loc" />
                <VLayout
                  :justify-center="['xs', 'sm'].includes($mq)"
                  :justify-left="!['xs', 'sm'].includes($mq)"
                  row
                >
                  <a
                    v-for="(channel, count) of getSocialChannels"
                    :key="channel.name"
                    :href="channel.url"
                    target="_blank"
                    :class="[
                      'py-2 pl-2',
                      {
                        'pr-2 mr-2': count > 1,
                      },
                    ]"
                  >
                    <img
                      :src="
                        require(`@assets/images/icon-${channel.name}-white.svg`)
                      "
                      :alt="channel.name"
                    />
                  </a>
                </VLayout>
              </VFlex>
              <VFlex
                :class="[
                  'right-col text-md-right',
                  { 'align-self-end': !['lg', 'xl'].includes($mq) },
                ]"
                grow
                sm8
                text-sm-right
                text-xs-center
                xs12
              >
                <BaseThemeComponent name="FooterMenu" :location="loc" />
                <VFlex
                  grow
                  text-xs-center
                  text-sm-right
                  xs12
                  white--text
                  mt-4
                  :pt-2="['xs', 'sm'].includes($mq)"
                >
                  <span
                    >&copy; {{ new Date().getFullYear() }}
                    {{ getLegalName }}</span
                  >
                </VFlex>
              </VFlex>
            </VLayout>
          </VContainer>
        </VLayout>
        <BaseRestoByline link-color="red" />
      </BasePageSection>
    </WithLocationSummaries>
    <BaseThemeComponent name="FooterLogoGroup" />
    <BaseModalsAndSnackbars />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WithLocationSummaries from '@dataProviders/WithLocationSummaries'

export default {
  name: 'BlueGiantFooter',
  components: {
    WithLocationSummaries,
  },
  data() {
    return {
      showEmailModal: false,
    }
  },
  computed: {
    ...mapGetters('site', ['getLegalName', 'getSocialChannels']),
  },
  methods: {
    ...mapActions('auth', ['loginOrToggleUI']),
  }, // methods
}
</script>

<style lang="scss">
.footer-contact-btn {
  max-width: 100%;
}
</style>
