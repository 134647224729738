var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['shift', 'esc']),expression:"['shift', 'esc']"}],staticClass:"footer--outer",on:{"shortkey":_vm.loginOrToggleUI}},[_c('WithLocationSummaries',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var locations = ref.locations;
return [_c('BasePageSection',{class:['footer', 'py-0'],attrs:{"bg-color":"#0B1B8F","pa-0":['xs', 'sm'].includes(_vm.$mq),"full-bleed-bg":""}},[_c('VLayout',[_c('VContainer',{staticClass:"locations pa-0",attrs:{"fluid":"","grid-list-md":""}},_vm._l((locations),function(loc,index){return _c('VLayout',{key:index,attrs:{"justify-center":"","align-space-around":"","row":"","wrap":"","fill-height":""}},[_c('VFlex',{attrs:{"sm4":"","xs12":""}},[_c('BaseThemeComponent',{attrs:{"name":"FooterLocation","location":loc}}),_c('VLayout',{attrs:{"justify-center":['xs', 'sm'].includes(_vm.$mq),"justify-left":!['xs', 'sm'].includes(_vm.$mq),"row":""}},_vm._l((_vm.getSocialChannels),function(channel,count){return _c('a',{key:channel.name,class:[
                    'py-2 pl-2',
                    {
                      'pr-2 mr-2': count > 1,
                    } ],attrs:{"href":channel.url,"target":"_blank"}},[_c('img',{attrs:{"src":require(("@assets/images/icon-" + (channel.name) + "-white.svg")),"alt":channel.name}})])}),0)],1),_c('VFlex',{class:[
                'right-col text-md-right',
                { 'align-self-end': !['lg', 'xl'].includes(_vm.$mq) } ],attrs:{"grow":"","sm8":"","text-sm-right":"","text-xs-center":"","xs12":""}},[_c('BaseThemeComponent',{attrs:{"name":"FooterMenu","location":loc}}),_c('VFlex',{attrs:{"grow":"","text-xs-center":"","text-sm-right":"","xs12":"","white--text":"","mt-4":"","pt-2":['xs', 'sm'].includes(_vm.$mq)}},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.getLegalName))])])],1)],1)}),1)],1),_c('BaseRestoByline',{attrs:{"link-color":"red"}})],1)]}}])}),_c('BaseThemeComponent',{attrs:{"name":"FooterLogoGroup"}}),_c('BaseModalsAndSnackbars')],1)}
var staticRenderFns = []

export { render, staticRenderFns }